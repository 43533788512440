$requestBar-dot-width: 8px;
$requestBar-bg-active: $primary;
$requestBar-bg: lighten($requestBar-bg-active, 20%);

/*
    RTFM
    .requestBar-container (position: relative)
    .requestBar -> a part of the vertical bar (position: absolute
    .requestBar-top, requestBar-bottom -> adds the rounded top / bottom
    .requestBar-active, .requestBar-top-active, requestBar-bottom-active -> adds a ::before with brighter blue
    .requestBar-refused .requestBar -> makes the lightblue turn grey
    .requestBar-dot -> the white dot
*/

.requestBar {
    @mixin requestBar {
        padding: 3px;
        position: absolute;
        left: 0;

        display: flex;
    }

    @mixin requestBar-active {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $requestBar-bg-active;
    }

    &-container {
        position: relative;
        width: calc(#{$requestBar-dot-width} + #{$card-spacer-x});
    }

    & { // requestBar
        @include requestBar;
        top: 0;
        bottom: 0;
        align-items: center;
        background-color: $requestBar-bg;



        &-active::before {
            content: "";
            @include requestBar-active;
        }
    }

    &-refused & {
        background-color: $gray-400
    }

    &-top {
        @include requestBar;
        top: calc(50% - #{$requestBar-dot-width});
        border-top-left-radius: $requestBar-dot-width;
        border-top-right-radius: $requestBar-dot-width;
        align-items: flex-start;

        &-active::before {
            content: "";
            @include requestBar-active;
            border-top-left-radius: $requestBar-dot-width;
            border-top-right-radius: $requestBar-dot-width;

        }
    }


    &-bottom {
        @include requestBar;
        bottom: calc(50% - #{$requestBar-dot-width});
        border-bottom-left-radius: $requestBar-dot-width;
        border-bottom-right-radius: $requestBar-dot-width;
        align-items: flex-end;

        &-active::before {
            content: "";
            @include requestBar-active;
            bottom: calc(50% - #{$requestBar-dot-width});
            border-bottom-left-radius: $requestBar-dot-width;
            border-bottom-right-radius: $requestBar-dot-width;
        }


        // quick patch, .requestBar-bottom.requestBar-bottom-active, the highlighed part has bottom: 0
        &#{&}-active::before {
            bottom: 0;
        }
    }

    &-dot {
        width: $requestBar-dot-width;
        height: $requestBar-dot-width;
        display: inline-block;
        background-color: #fff;
        border-radius: 50%;
        position: relative;
        z-index: 1;
    }
}