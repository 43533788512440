.dashboard {
    @mixin bottomLine($height)  {
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 80px;
            height: $height;
            background-color: $primary
        }
    }

    @include media-breakpoint-up(md) {
        &-bg {
            background-image: url('../../../images/DashboardPlan.png');
            background-position: top right;
            background-size: calc(2/3*100%) auto; // col-md-8
            background-repeat: no-repeat;
        }
    }

    &-title {
        // <div className="mb-3 mt-1 w-25 border-primary" style={{border:"4px solid"}}></div>
        position: relative;
        padding-bottom: map-get($spacers, 3);
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100px;
            height: map-get($spacers, 2);
            background-color: $primary
        }
    }

    &-subtitle {
        // <div className="mb-3 mt-1 w-25 border-primary" style={{border:"4px solid"}}></div>
        position: relative;
        padding-bottom: map-get($spacers, 2);
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 50px;
            height: map-get($spacers, 1);
            background-color: $primary
        }
    }

    &-subtitle {

    }

    &-addModal {
        &-preview {
            &-medium,&-large,&-tall{
                width: 100%;
                height: 500px;
            }
            &-small {
                width: 100%;
                @include media-breakpoint-up(lg){
                    max-width: 40%;
                    height: 50%;
                }
            }
            &-medium {
                @include media-breakpoint-up(lg){
                    width: 75%; // not max-width because carousel's width would wobble and do zoop zoop when changing widget
                    height: 450px;
                }
            }
            &-large {
                @include media-breakpoint-up(lg){
                    max-width: 100%;
                    height: 100%;
                    max-height: 450px;
                }
            }
            &-tall {
                @include media-breakpoint-up(lg){
                    max-width: 75%;
                    height: 100%;
                    max-height: 500px;
                }
            }
        }
    }
}