@use "sass:math";

.quick-view {
    &-iframe-body:not(.modal-open) {
        overflow-x: hidden;
        overflow-y: scroll;
    }
    &-header {
        position: fixed;
        width: 100%;
        z-index: 1032;

        &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background-image: linear-gradient(to top, rgba($dark, 0.025), transparent);
            z-index: 10;
        }
    }
    &-modal {
        border-radius: $border-radius-lg * 2;
        overflow: hidden;
    }
    &-container {
        z-index: $zindex-popover;
    }
    &-toggle {
        width: 50px;
        height: 50px;
        border-radius: 50%;

    }
    &-handle {
        cursor: move !important;
        position: absolute;
    }
    &-tabs-container {
        overflow: hidden;
    }
    &-tab {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        background-color: $light;
        min-width: 0;
        position: relative;
        z-index: 10;
        transition: border-radius 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &.is-dragging {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            background-color: $white;
            box-shadow: $box-shadow;
        }

        &:hover {
            background-color: $light-inverse;
        }

        &.is-active {
            border-top-right-radius: $border-radius;
            border-top-left-radius: $border-radius;
            background-color: $white;
            z-index: 11;
            box-shadow: 0 0 5px rgba($dark,0.15);

            &:not(.is-dragging)::before {
                content: "";
                background-color: transparent;
                border-bottom-right-radius: map-get($spacers, 2);
                width: map-get($spacers, 3);
                height: map-get($spacers, 2);
                position: absolute;
                left: 0;
                transform: translateX(-100%);
                bottom: 0;
                box-shadow: math.div(map-get($spacers, 3), 2) 0 0 $white;
            }

            &:not(.is-dragging)::after {
                content: "";
                background-color: transparent;
                border-bottom-left-radius: map-get($spacers, 2);
                width: map-get($spacers, 3);
                height: map-get($spacers, 2);
                position: absolute;
                right: 0;
                transform: translateX(100%);
                bottom: 0;
                box-shadow: -(math.div(map-get($spacers, 3), 2)) 0 0 $white;
            }
        }


        &.is-active &-name::after {
            background-image: linear-gradient(to left, $white, transparent);
        }

        &.is-dragging &-name::after {
            background-image: linear-gradient(to left, $white, transparent);
        }

        &:hover &-name::after {
            background-image: linear-gradient(to left, $light-inverse, transparent);
        }

        &:not(.is-active) &-inner::after {
            content: "";
            display: block;
            width: 1px;
            height: 50%;
            background-color: $text-muted;
            position: absolute;
            left: -(math.div(map-get($spacers, 1), 2));
            top: 50%;
            transform: translateY(-50%);
            bottom:0;
            opacity: 0.5;
        }

        &.is-active + & &-inner::after {
            display: none;
        }

        &-dropdown {
            border-radius: $border-radius;

            &:hover {
                background-color: $light-inverse;
            }
            .show.dropdown & {
                background-color: $white;
                box-shadow: 0 0 5px rgba($dark, 0.15);
            }

            &-menu {
                max-height: 66vh;
                overflow-x: hidden;
                overflow-y: auto;
            }

            &-item {
                font-size: 12px;
                padding: 0;

                &:hover,
                &:focus,
                &:active,
                &:focus:active {
                    color: $dark;
                    background-color: transparent;
                }

                &.is-active &-inner {
                    background-color: $light;
                    font-weight: 500;
                }

                &:hover &-inner {
                    background-color: $light-inverse;
                }

                &-inner {
                    margin: 0 math.div($spacer, 2) math.div($spacer, 2) math.div($spacer, 2) ;
                    border-radius: $border-radius;
                    padding: map-get($spacers, 2) math.div($spacer, 2);
                }
            }

            &-close:hover {
                color: $white;
                background-color: #eb6868;
                border-radius: $border-radius;
            }
        }

        &-name {
            font-size: 12px;

            &::after {
                background-image: linear-gradient(to left, $light, transparent);
                content: "";
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 15px;
            }
        }
    }
}

// This is temporary, since it might break if there is an update.
// Needs more research for better customization.
@mixin fontAwesomeIcon($content) {
    content: $content;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

@mixin mdiIcon($content) {
    content: $content;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.quick-view-handle:not(.react-draggable-dragging) {
    -webkit-transition: -webkit-transform 0.25s ease-out; /* Safari */
    transition: transform 0.25s ease-out;
}