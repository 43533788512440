.help-dropdown {
    &-container {
        position: fixed;
        bottom: 1vw;
        right: 1vw;
        z-index: $zindex-popover;
    }
    &-toggle {
        width: 50px;
        height: 50px;
        border-radius: 50%;

    }

    &-menu {
        padding: 0;
        border-radius: $border-radius-lg;
        overflow: hidden;
    }


    &-item {
        border-top: 1px solid $border-color;

        i {
            color: $primary;
        }

        &:hover {
            background-color: $light;

            i {
                color: inherit;
            }
        }
    }

    &-header {
        font-size: inherit;
        color: $dark;
        font-weight: $font-weight-bold;
    }



}

// This is temporary, since it might break if there is an update.
// Needs more research for better customization.
@mixin fontAwesomeIcon($content) {
    content: $content;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

@mixin mdiIcon($content) {
    content: $content;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}


.help-dropdown-menu-handle {
    cursor: move;
    position: absolute;
}

.help-dropdown-menu-handle:not(.react-draggable-dragging) {
    -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
    transition: transform 0.5s ease-out;
}

#chat-container {
    & .spartez-support-chat-container {
        @include media-breakpoint-up(sm) {
            &.help-dropdown-menu {
                border-bottom-left-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
            }
        }

        & .top-header {
            background-color: $primary;

            @include media-breakpoint-up(sm) {
                border-top-left-radius: $border-radius-lg;
                border-top-right-radius: $border-radius-lg;
            }
        }
        & .op.back-from-chat,
        & .op.close {
            background-image: none;
            font-size: 1.5rem;

            &::before {
                @include mdiIcon("\F141");
            }
        }
        & .op.back-from-chat::before {
            content: "\F141";
        }
        & .op.close::before {
            content: "\F156";
        }
        & .op.back-from-chat,
        & .op.close,
        & .issue-link a {
            border-radius: 1000px;
            &:hover {
                background-color: darken($primary, 7.5%);
            }
        }
        & .top-shadow {
            display: none;
        }
        & .chat-message.my {
            background-color: $primary;

            & p {
                color: $white;
            }
        }
        & .chat-conversation:hover {
            border-color: $primary;
        }
        & .new-conversation {
            font-family: $font-family-sans-serif;
            font-weight: normal;
            background-image: none;
            background-color: $primary;
            border-radius: $btn-border-radius;
            box-shadow: none;

            &:hover {
                box-shadow: none;
                background-color: darken($primary, 7.5%);
            }
        }
        & .message-entry {
            & .add-content {
                &:hover::before {
                    color: $primary;
                }

                &.add-attachment {
                    background-image: none;

                    &::before {
                        @include mdiIcon("\F066");
                    }
                }

                &.add-smiley {
                    background-image: none;

                    &::before {
                        @include fontAwesomeIcon("\f118");
                    }
                }
            }
            & .send-message {
                background-color: $primary;

                &:disabled {
                    opacity: 0.75;
                    pointer-events: none;

                    &:hover {
                        background-color: $primary;
                    }
                }

                &:hover {
                    background-color: darken($primary, 7.5%);
                }
            }
        }
    }
}