@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Outer {
    height: 8px;
    width: 8px;
    display: inline-block;
    background-color: v.$primary;
    animation-timing-function: linear;
    animation-name: blink;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 8px;
    border: none;
    flex-shrink: 0;

    &:focus {
        outline: none;
    }

    &.lg {
        height: 12px;
        width: 12px;
        animation-name: blinkLg;
    }
    &.sm {
        height: 6px;
        width: 6px;
    }
}

@keyframes blinkLg {
    0% {
        box-shadow: 0 0 0 3px rgba(v.$primary, 0.3);
    }
    50% {
        box-shadow: 0 0 0 4px rgba(v.$primary, 0.25);
    }
    100% {
        box-shadow: 0 0 0 3px rgba(v.$primary, 0.3);
    }
}

@keyframes blink {
    0% {
        box-shadow: 0 0 0 2px rgba(v.$primary, 0.3);
    }
    50% {
        box-shadow: 0 0 0 3px rgba(v.$primary, 0.25);
    }
    100% {
        box-shadow: 0 0 0 2px rgba(v.$primary, 0.3);
    }
}