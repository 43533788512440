@use "sass:map";

/// Hardcoded values
/// Hard coded stuff to get rid of when refactoring bootstrap 5 + ui kit
/// Should be removed after refactor
/// Other places in newNav will contain - > REFACTOR TOREMOVE
/// Lots of cleanup to do

.topbar .sid-nav-bar.top-navbar .navbar-nav > .nav-item:hover {
    background-color: transparent;
}

// Card titles
.card .card-title::before{
    border-color: transparent;
}
// Tabs
.nav-tabs .nav-item .nav-link.active{
    color: black !important;
}

.sid-nav-bar .navbar-nav .nav-link {
    color: inherit;
}

.sid-top-bar .dropdown-menu {
    padding-top: map.get($spacers, 2);
}

.sid-nav-bar .navbar-nav .nav-link:hover,
.sid-nav-bar .navbar-nav .nav-link:focus {
    color: $dark;
}

.sid-page-wrapper .subSidebar-wrapper.is-open .page-content {
    margin-left: 0;
}

.sid-sidebar.left-sidebar {
    width: auto;
}

.sid-sidebar .sidebar-nav ul .sidebar-item .sidebar-link {
    line-height: 1;
    opacity: 1;
}

.sid-sidebar .sidebar-nav ul .sidebar-item.selected > .sidebar-link {
    background-color: transparent;
}

#main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar].sid-main-wrapper {
    .left-sidebar {
        width: auto;
        overflow: visible;

        &:hover {
            width: auto;
        }
    }
    @include media-breakpoint-up(lg) {
        .page-wrapper {
            margin-left: $sidebar-min-size;
        }
    }
}

@include media-breakpoint-only(md) {
    #main-wrapper[data-layout=vertical].sid-main-wrapper .page-wrapper {
        margin-left: $sidebar-min-size;
    }
}

#main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .sid-sidebar-inner:not(:hover) .hide-menu {
    visibility: hidden;
}

#main-wrapper[data-layout=vertical][data-header-position=fixed] .sid-page-wrapper {
    display: block;
    min-height: 100vh;
    overflow-x: hidden;
}

@include media-breakpoint-down(lg) {
    .sid-main-wrapper .topbar .top-navbar {
      .navbar-nav {
        .dropdown {
          position: relative;
        }
      }
    }
}