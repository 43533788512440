.profileHeader {
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
        display: flex;
        align-items: start;
    }

    &-image {
        object-fit: contain;
        border-radius: 50%; // asuming image is always squared
        border: $border-width solid $border-color;
        background-color: $white;
        padding: map-get($spacers, 3);

        // image-container
        &-container {
            display: inline-block;
            margin-bottom: map-get($spacers, 4);
            position: relative;

            @include media-breakpoint-up(md) {
                margin-right: map-get($spacers, 4);
            }
        }

        &-picker-tooltip {
            position: absolute;
            right: 0;
            bottom: 0;
            :hover {
                transform: translateY(-1px);
            }
        }
        &-picker-badge {
            font-size: 100%;
            line-height: 1.15;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: $box-shadow;
        }
    }

    &-info-container {
        color: $dark;
    }
}