.Container {
    position: relative;
    display: inline-block;
}

.PopContainer {
    background-color: white;
    z-index: 1000;
    position: absolute;
    top: 100%;
    margin-top: 5px;
    width: 260px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.15);
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &.isOpen {
        opacity: 1;
        pointer-events: all;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transform: translateY(-100%);
        margin: 0 auto;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
    }
}

.Close {
    position: absolute;
    top: 0;
    right: 0;
}