@use "sass:map";
@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.rolloverCard {
    margin-bottom: map.get(v.$spacers, 2);
    flex-wrap: wrap;

    .feeDisplay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;

        .oldData {
            padding-right: map.get(v.$spacers, 4);
            padding-left: map.get(v.$spacers, 4);
            transition: flex-basis 0.25s ease-in-out, opacity 0.25s ease-in-out, margin 0.25s ease-in-out, padding 0.25s ease-in-out;
            text-align: center;
            flex-basis: 50%;

            &.hasSubmitted {
                padding-right: 0;
                padding-left: 0;
                margin-right: 0;
                flex-basis: 0;
                opacity: 0;
            }
        }

        .newData {
            transition: flex-basis 0.25s ease-in-out, opacity 0.25s ease-in-out, margin 0.25s ease-in-out, padding 0.25s ease-in-out;
            padding-right: map.get(v.$spacers, 4);
            padding-left: map.get(v.$spacers, 4);
            text-align: center;
            flex-basis: 50%;

            &.hasSubmitted {
                padding-right: 0;
                padding-left: 0;
                text-align: left;
                flex-basis: 100%;
            }
        }

        .arrow {
            flex-shrink: 1;
            width: 30px;
            transition: flex-basis 0.25s ease-in-out, opacity 0.25s ease-in-out, margin 0.25s ease-in-out, width 0.10s ease-in-out;

            &.hasSubmitted {
                width: 0;
                margin-right: 0;
                flex-basis: 0;
                opacity: 0;
            }
        }

        .configure {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 1;
            transition: opacity 0.25s ease-in-out;

            &.hasSubmitted {
                opacity: 0;
            }
        }

        .icon {
            opacity: 0;
            width: 0;
            transition: opacity 0.25s ease-in-out, width 0.25s ease-in-out;
            transition-delay: 0.15s;

            &.hasSubmitted {
                opacity: 1;
                width: 25px;
            }
        }
    }
}