@use "../../../../assets/scss/variables" as v;
@use "sass:map";

.step {
    cursor: pointer;
    opacity: 1;

    &.inactiveStep {
        opacity: 0.85;
    }
}