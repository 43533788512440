@use "sass:map";
@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Section {
    background-color: v.$white;
    border-radius: v.$border-radius-lg * 2;
    box-shadow: v.$box-shadow;
    flex-shrink: 0;
    overflow: hidden;
    width: 100%;
    min-height: 90vh;

    @media screen and (min-width: 768px) {
        min-height: min(75vh, 600px);
    }

    @media screen and (min-width: 992px) {
        min-height: min(50vh, 600px);
    }
}

.Figure {
    background-color: v.$gray-100;
    display: block;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding-top: 75%;
    }

    @media screen and (min-width: 992px) {
        padding-top: 33%;
    }

    &Img {
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.ListItem {
    padding: v.$spacer 0;

    &:first-of-type {
        padding-top: v.$spacer * 2;
    }

    &:last-of-type {
        padding-bottom: v.$spacer * 2;
    }
}