.img {
    &Dashboard {
        left: 50%;
        transform: translate(0, 50%);
        opacity: 0.75;
    }
}

.title {
    font-size: 2.5rem;

    @media screen and (min-width: 992px) {
        font-size: 3.5rem;
    }
}