@use "sass:map";
@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Container {
    background-image: linear-gradient(to top right, #e7ecf7, #fbfcfd);
    padding-top: 40%;
    position: relative;
    overflow: hidden;

    &.Slim {
        padding-top: 40%;
    }

    &Inner {
        top: map.get(v.$spacers, 5);
        left: map.get(v.$spacers, 5);
        right: map.get(v.$spacers, 5);
        bottom: 0;
        position: absolute;

        &.Slim {
            top: map.get(v.$spacers, 2);
        }
    }
}

.Img {
    position: absolute;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(.7,-0.22,.14,1.2);
    animation-fill-mode: forwards;
    max-height: 100%;

    .Reverse & {
        animation-direction: reverse;
    }

    .Animated & {
        &.Dashboard {
            transform: translateY(100%);
            animation-name: slideUp;
        }
        &.Dots {
            transform: translateY(-200%);
            animation-name: slideDown;
        }
        &.Widget01 {
            transform: translateX(-200%);
            animation-name: slideRight;
        }
        &.Widget02 {
            animation-name: slideLeft;
            transform: translateX(200%);
        }

    }
}

.Dashboard {
    bottom: -6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 80%;
    display: block;
}

.Dots {
    top: 0;
    left: 0;
    max-height: 50%;
    max-width: 33%;
}

.Widget {
    &01 {
        left: 0;
        bottom: 0;
        max-width: 25%;
    }
    &02 {
        max-width: 20%;
        right: 0;
        bottom: 0;
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-200%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(200%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(-200%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}