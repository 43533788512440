.Card {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: space-evenly;

    &::before {
        content: "";
        display: block;
        height: 0.5em;
    }
}