.clinic {
    @each $key, $colors in $clinicColors-gradient {
        &-header-#{$key} {
            @include gradient-y( map-get($colors, 'start'), map-get($colors, 'end') );
        }
    }

    &-btn-container {
        display: flex;

        @include media-breakpoint-down(lg) {
            overflow: auto;
        }

    }

    &-btn {
        flex: 0 0 200px;
        margin-top: map-get($spacers, 2);
        margin-right: map-get($spacers, 3);
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
    }
}