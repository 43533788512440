@use "sass:math";
@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

$btnPadding: 4px;

.btn {
    color: v.$secondary;
    position: relative;
    font-weight: 500;
    border: v.$border-width solid transparent;
    border-radius: v.$border-radius;
    margin: (-$btnPadding + v.$border-width) (-$btnPadding) 0 (-($btnPadding + v.$border-width));
    padding: $btnPadding;
    width: auto;
    transition: background-color 0.15s ease-in-out, border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    :global(.dropdown.show) & {
        border: v.$border-width solid v.$border-color;
        text-decoration: none;
    }

    &:hover {
        background-color: v.$white;
        box-shadow: v.$box-shadow-sm;
    }

    :global(.dropdown.show) & {
        background-color: v.$light-inverse;
        box-shadow: inset 0px 1px 2px rgba(0,0,0,0.1);
    }

    &.disabled {
        font-weight: unset;
        border: unset !important;
        background-color: unset !important;
        box-shadow: unset !important;
    }
}