@each $colorKey, $colorValue in $clinicColors {
    .bg-clinic-#{$colorKey} {
        background-color: $colorValue !important;
    }
}

.w {
    @each $size, $length in $sizes {
        &-#{$size} {
            width: $length !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    width: $length !important;
                }
            }
        }
    }
}

.min-h-100 {
    min-height: 100%;
}

.min-w {
    @each $size, $length in $sizes {
        &-#{$size} {
            min-width: $length !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    min-width: $length !important;
                }
            }
        }
    }
}

.max-w {
    @each $size, $length in $sizes {
        &-#{$size} {
            max-width: $length !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    max-width: $length !important;
                }
            }
        }
    }
}

.h {
    @each $size, $length in $sizes {
        &-#{$size} {
            height: $length !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    height: $length !important;
                }
            }
        }
    }
}

.min-h {
    &-0 {
        min-height: 0 !important;
    }
    @each $size, $length in $sizes {
        &-#{$size} {
            min-height: $length !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    min-height: $length !important;
                }
            }
        }
    }
}

.max-h {
    @each $size, $length in $sizes {
        &-#{$size} {
            max-height: $length !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    max-height: $length !important;
                }
            }
        }
    }
}

.vh {
    @each $size, $length in $sizes {
        &-#{$size} {
            height: #{$size}vh !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    height: #{$size}vh !important;
                }
            }
        }
    }
}

.max-vh {
    @each $size, $length in $sizes {
        &-#{$size} {
            max-height: #{$size}vh !important;
        }
    }
    @each $key, $value in $grid-breakpoints {
        @each $size, $length in $sizes {
            &-#{$key}-#{$size} {
                @include media-breakpoint-up(#{$key}) {
                    max-height: #{$size}vh !important;
                }
            }
        }
    }
}

.min-vh-50 {
    min-height: 50vh;
}

.max-vh-25 {
    max-height: 25vh !important;
}

.top-25 {
    top: 25%;
}

.square-element {
    position: relative;

    &-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &::before {
        content: '';
        padding-bottom: 100%;
        display: block;
    }
}