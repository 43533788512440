.tiny-mce-custom-container {
    .tox.tox-tinymce {
        border: 1px solid rgba(0, 0, 0, 0.2);
        @include border-radius($input-border-radius, 0);
    }

    &.is-error .tox.tox-tinymce {
        border-color: $danger;
    }

    &.is-error:focus .tox.tox-tinymce,
    &.is-error:focus-within .tox.tox-tinymce {
        box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
    }
}