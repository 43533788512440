$subSidebar-handle-width: 1.25rem;
.subSidebar {
    &-handle {
        display: block;
        width: $subSidebar-handle-width;
        height: 1.75rem;
        position: fixed;
        z-index: $zindex-fixed - 1; // handle goes under sidebar when collapsed in mobile
        left: $sidebar-width-full;
        top: calc(#{$topbar-height} + #{$viewHeader-height} + 4px);
        background-color: $primary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        border: 2px solid $light;
        border-top-right-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        transition: all $subSidebar-transition-duration $subSidebar-transition-ease;

        .mini-sidebar & {
            left: $sidebar-width-mini;
        }
    }

    &-wrapper {
        position: relative; //does not work because of position sticky

        &.is-open {
            .subSidebar-handle {
                margin-left: calc(#{$subSidebar-width} - #{$subSidebar-handle-width});

                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: $border-radius-lg;
                border-bottom-left-radius: $border-radius-lg;
            }
            .page-content {
                margin-left: $subSidebar-width;
            }
            .subSidebar-container {
                width: $subSidebar-width;
            }
            .subSidebar {
                // visibility: visible after transition
                animation: delayedVisible;
                animation-delay: $subSidebar-transition-duration;
                animation-fill-mode: forwards;
            }
        }

        .page-content {
            transition: margin $subSidebar-transition-duration $subSidebar-transition-ease;
        }
    }


    &-container {
        background-color: lighten($skin5, 5%); // $skin5 is the sidebar's background
        position: absolute; // position:fixed does not work because of the closed sidebar
        left: 0;
        top: 0;
        bottom: 0;
        width: $subSidebar-width;
        white-space: nowrap;
        transition: width $subSidebar-transition-duration $subSidebar-transition-ease ;
        width: 0;
    }

    & { //.subsidebar
        visibility: hidden;
        position: fixed; // for adding the scrollbar
        bottom: 0;
        width: $subSidebar-width;
        top: $topbar-height + $viewHeader-height;
    }

    &-item {
        padding: map-get($spacers, 2);
        display: block;
        color: $body-color;
        font-weight: $font-weight-medium;

        &:hover {
            color: $white;
        }

        &.is-active {
            color: $white;
            background-color: rgba($primary, $alpha: 0.2);
        }
    }

    &-title {
        padding: map-get($spacers, 2);
        font-weight: $font-weight-medium;
        color: $primary;
        border-top: $border-width solid $gray-700;
    }

    &-add {
        color: $white;

        &:hover {
            color: $gray-400;
        }
    }

    &-icon {
        width: 1rem;
        margin-right: map-get($spacers, 2);
        text-align: center;
        display: inline-block;
    }
}

@include media-breakpoint-down(sm) {
    .mini-sidebar .subSidebar-handle {
        left: 0;
    }
}

@keyframes delayedVisible {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}