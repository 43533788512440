.watermark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0.7;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transition: opacity 0.25s ease-in-out;
    z-index: $zindex-dropdown;

    .sidePanel & {
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        animation-name: fadeIn;
        animation-timing-function: ease-in-out;
        position: fixed;
        left: unset;
        max-width: 400px;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    50% {
        transform: translateX(0);
    }
    100% {
        opacity: 0.7;
    }
}