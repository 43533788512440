.wrapper {
    &:hover {
        .btn {
            opacity: 1;
        }
    }
}

.btn {
    opacity: 0;
}