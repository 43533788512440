@use "sass:map";
@use "sass:math";

.sid-sidebar {
    background: #FAFBFC !important; // REFACTOR TOREMOVE - remove !important; and make this a background-color instead of background
    position: fixed;
    height: 100%;
    top: 0px;
    z-index: $zindex-fixed;
    padding-top: $topbar-height;
    box-shadow: $shadow;
    border-right: 1px solid $border-color;

    .sidebar-nav {
        font-weight: 400;
    }

    &-manage-profile {
        margin-top: map.get($spacers, 2);
    }
    .sid-main-wrapper &-manage-profile.active.sidebar-link { // REFACTOR TOREMOVE - remove selector specificity
        background-color: $light !important; // REFACTOR TOREMOVE - remove !important;
    }

    &-org{
        &-name {
            width: 100%;
        }
        &-info {
            color: $secondary;
            line-height: initial;
            font-size: 75%;
            font-weight: 500;
        }
    }
    &-collapse-btn {
        align-items: center;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 1000px;
        box-shadow: $box-shadow-sm;
        color: $text-muted;
        display: flex;
        justify-content: center;
        height: 25px;
        width: 25px;
        transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &-container:hover &,
        &:hover {
            border-color: $fedColor;
            color: $fedColor;
        }

        &-container {
            cursor: pointer;
            padding: map.get($spacers, 3);
            position: absolute;
            top: calc(70px + $spacer);
            transform: translateX(50%);
            right: 0;
            z-index: $zindex-fixed + 1;
        }
        &:focus {
            outline: none;
        }

        & .mdi-chevron-left {
            display: inline-block;
            transition: transform 0.15s ease-in-out;
        }

        #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] & .mdi-chevron-left {
            transform: rotate(180deg);
        }
    }

    #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar].sid-main-wrapper &.is-open &-inner {
        transition-delay: 0.3s;
        width: $sidebar-width-full;

        .hide-menu {
            opacity: 1;
            transition-delay: 0.2s;
            visibility: visible;
        }
    }

    &-inner {
        height: 100%;
        overflow-x: hidden;
        transition: width 0.2s ease-in;
        width: $sidebar-width-full;

        #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar].sid-main-wrapper & {
            width: $sidebar-min-size;

            .hide-menu {
                opacity: 0;
                transition: opacity 0.15s ease-in-out;
            }
        }
    }

    &-item {
        &-title {
            align-items: center;
            color: $dark-title-color !important; // REFACTOR TOREMOVE - remove !important
            display: flex;
            font-size: 0.8rem;
            opacity: 1 !important; // REFACTOR TOREMOVE - remove !important
            justify-content: flex-start !important; // REFACTOR TOREMOVE - remove !important
            font-weight: 800;
            position: relative;
            padding: map.get($spacers, 2) $spacer;
            white-space: nowrap;
        }
        &-chevron {
            font-size: 0.7em;
            position: absolute;
            left: map.get($spacers, 2);
            transform: translateX(-50%);
        }
    }

    .sidebar-widget-link {
        border-radius: $border-radius;
        display: block;
        padding: map.get($spacers, 2);
        position: relative;
        transition: background-color 0.15s ease-in-out;

        &:hover {
            background-color: $light-inverse;
        }

        &.active {
            background-color: $light-inverse !important; // REFACTOR TOREMOVE - remove !important

            &::before {
                content: "";
                background-color: $fedColor;
                width: 3px;
                height: 80%;
                position: absolute;
                left: 0;
                top:50%;
                bottom: 0;
                transform:translateY(-50%);
            }
        }
    }

    &-list {
        background-color: inherit !important; // REFACTOR TOREMOVE - remove !important
        padding: map.get($spacers, 1) 0 !important; // REFACTOR TOREMOVE - remove !important
    }

    .sid-main-wrapper &-main-list {
        .sidebar-item {
            max-width: 100%;

            &:hover .sidebar-link {
                background-color: $light;
            }
        }
        .sidebar-favorites-list .sidebar-link-star {
            opacity: 0.5;
        }
        .sidebar-link {
            background-color: transparent;
            border-radius: 0.2rem;
            border: none;
            outline: none;
            display: flex;
            padding: map.get($spacers, 2) 0.5rem !important;
            position: relative;
            transition: background-color 0.15s ease-in-out;

            &:hover {
                background-color: $light;
            }

            &.active {
                background-color: $light !important;

                &:hover {
                    background-color: $light !important; // REFACTOR TOREMOVE - remove !important
                }
            }
        }
    }
    &-footer {
        border-top: 1px solid $border-color;

        &-logo:hover {
            color: $spordle;
        }
    }
}

.sid-main-wrapper {
    .sidebar-item {
        font-size: $nav-font-size;
        padding: 0 map.get($spacers, 2);
    }

    .sid-sidebar-main-list .sidebar-link-star {
        margin: 0;
    }

    .sidebar-link {
        border-radius: $border-radius;
        display: block;
        padding: math.div($spacer, 1.25) $spacer !important; // TOREMOVE Remove important
        position: relative;
        transition: background-color 0.15s ease-in-out;


        &-star {
            background-color: transparent;
            opacity: 0;
            transition: opacity 0.15s ease-in-out, color 0.16s ease-in-out;
            margin: (-(math.div($spacer, 1.25))) (-(map.get($spacers, 2))) (-(math.div($spacer, 1.25))) 0;


            &.is-favorite {
                color: $fedColor;
            }

            &:hover {
                color: $fedColor;
                opacity: 1;
            }
        }

        &:hover .sidebar-link-star {
            opacity: 1;
        }

        &:hover {
            background-color: $light-inverse;
        }

        &.active {
            background-color: $light-inverse !important; // REFACTOR TOREMOVE - remove !important

            &::before {
                content: "";
                background-color: $fedColor;
                width: 3px;
                height: 80%;
                position: absolute;
                left: 0;
                top:50%;
                bottom: 0;
                transform:translateY(-50%);
            }
        }
    }
}

.sidebar-favorites-list {
    background-color: darken($light-inverse, 1%) !important; // REFACTOR TOREMOVE - remove !important
    box-shadow: inset 0 2px 4px rgba($dark, 0.1);
}