@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;
@use "sass:map";

.Label {
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    background-color: v.$gray-100;
    margin-bottom: 0;
    border: 1px solid darken(v.$gray-100, 12%);
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
        transform: translateY(-3px);
        box-shadow: v.$shadow;
    }

    &::after {
        content: "";
        width: 100%;
        height: 0;
        padding-bottom: 110%;
        display: block;
    }

    &.Selected {
        border: 1px solid v.$primary;
    }
}

.Img {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.Radio {
    position: absolute;
    top: map.get(v.$spacers, 2);
    left: map.get(v.$spacers, 2);
    z-index: 10;
}