.sid-newnav-toast {
    box-shadow: $box-shadow-lg, $box-shadow;
    border: 1px solid #999;

    .spdl-toast-loading-bar {
        display: none;
    }
    &.spdl-toast {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;

        > .px-2 {
            display: none;
        }
    }
    .spdl-toast-close {
        position: absolute;
    }
}