
$fedColor: #c71f23;
$spordle: #ff1744;
$nav-font-size: 0.8rem;
$font-family-sans-serif: "Rubik", "Montserrat", "Arial", sans-serif;

$sidebar-min-size: 30px;
$info: rgb(0, 82, 204); // for now only used for badge status "in progress"
$enable-validation-icons: false;
$border-radius: 0.2rem;

$clinicColors: (
    0: #0E60D5,
    1: #C51715,
    2: #0F7F22,
    3: #6A1A88,
    4: #F47721,
    5: #B91D73,
    // 6: #D3A50F //yellow disabled until we find a good fix for yellow texts on white bg
);

$borderSides: top, right, left, bottom;

$clinicColors-gradient: (
    0: (
        start: #4594F5,
        end: map-get($clinicColors, 0),
    ),
    1: (
        start: #EE3D20,
        end: map-get($clinicColors, 1),
    ),
    2: (
        start: #18AC37,
        end: map-get($clinicColors, 2),
    ),
    3: (
        start: lighten( map-get($clinicColors, 3), 20% ),
        end: map-get($clinicColors, 3),
    ),
    4: (
        start: #F47721,
        end: darken(map-get($clinicColors, 4), 10%),
    ),
    5: (
        start: #F953C6,
        end: map-get($clinicColors, 5),
    ),
);
