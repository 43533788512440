@use "sass:map";
@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Container {
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &.Active {
        background-color: lighten(v.$primary, 35%);

        .FooterImg {
            border: 2px solid lighten(v.$primary, 35%);
        }
    }

    &:hover {
        background-color: v.$gray-100;

        .FooterImg {
            border: 2px solid v.$gray-100;
            background-color: v.$white;
        }
    }

}

.Title {
    font-weight: 500;

    .Unread & {
        font-weight: 800;
    }
}

.Content {
    max-height: 1.1em;
    line-height: 1.1;
}

.Footer {
    padding-left: map.get(v.$spacers, 2);
    margin-left: -2px;

    .Container &Img {
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
        border: 2px solid v.$white;
        margin-left: -(map.get(v.$spacers, 2));
    }
}