.new-comment {
    animation-name: highlight;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

.select-request {
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
        box-shadow: $input-focus-box-shadow;
        cursor:pointer;
    }
}

@keyframes highlight {
    0% {
        background-color: rgba($primary, 0.5);
    }
    100% {
        background-color: transparent;
    }
}