.overlay-loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.5);
    z-index: 150;

    & .fade {
        opacity: 0.5;
    }
}