@use '~@spordle/ui-kit/assets/default/scss/all/custom/variables.scss' as v;

.container {
    position: relative;
    overflow: hidden;

    .row {
        border-top: 1px solid v.$border-color;
    }

    .innerRow {
        display: flex;

        &-col {
            width: 20%;
            text-align: center;
        }
    }

    .columnTitle {
        font-weight: bold;
        color: v.$gray-800;
        display: flex;
        align-items: center;
    }

    .rowTitle {
        font-weight: v.$font-weight-medium;
        color: v.$gray-800;
        display: flex;
        align-items: center;
    }

    .cell {
        padding: map-get(v.$spacers, 1);
    }

    &.isExpanded::after {
        content: none;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        height: 1.65em;
        background: linear-gradient(to top, #fff 0%, rgba(255,255,255,0) 100%);
    }
}