@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Dropdown {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        display: block;
        transform: translateX(-100%);
        width: 10px;
        background: linear-gradient(to left, v.$light-inverse, transparent);
    }
}