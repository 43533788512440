.selectAllSubOrgs::before {
    display: none !important;// TODO: Remove refactor !important
}

.input :global(.custom-control-label){
    align-items: center;
    display: inline-flex;
    line-height: 1;

    &::before,
    &::after {
        top: 50%;
        transform: translateY(-50%);
    }
}