@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Btn {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
        box-shadow: v.$shadow;
    }
}