@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Btn {
    background: transparent;
    position: absolute;
    width: 100%;
    height: 1rem;
    left: 0;
    right: 0;
    top: 0;
    border: 0;
    outline: 0;
    padding: v.$spacer 0;
    touch-action: pan-y;
    transition: transform 0.15s ease-in-out;
    z-index: 10;

    &:focus,
    &.is-dragging {
        &::after {
            background-color: darken(v.$gray-100, 3%);
            height: 5px;
        }

        outline: none;
    }

    &::after {
        background-color: v.$gray-100;
        box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
        margin: 0 auto;
        border-radius: 3px;
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        transform: translateY(-50%);
        transition: background-color 0.15s ease-in-out, height 0.15s ease-in-out;
    }
}