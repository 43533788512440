@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Container {
    box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 30%) !important;

    &.NotFull {
        width: 400px;
    }
}

.MoreBtn {
    background-color: transparent;
    width: 25px;
    height: 25px;

    &:hover {
        background-color: v.$gray-100;
    }
}

.Header {
    box-shadow: 0 0.15rem 0.25rem rgba(0,0,0,0.025);
}

.FooterTicket {
    box-shadow: 0 -0.25rem 0.25rem rgba(0,0,0,0.05);
}