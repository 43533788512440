@use "sass:math";

$favorite-star-off-color: #b5bbc1 !default;
$favorite-star-on-color: #dda407 !default;
$favorite-star-font-size: 1.8em !default;
$favorite-star-font-size-sm: 1.5em !default;
$favorite-star-dots-size: 3px !default;

.label {
    cursor: pointer;
    height: $favorite-star-font-size;
    width: $favorite-star-font-size;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;

    &.sm {
        height: $favorite-star-font-size-sm;
        width: $favorite-star-font-size-sm;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        border-radius:50%;
        height: 1px;
        width: 1px;
        position: absolute;
        top:50%;
        margin-top:-1px;
        margin-left:-1px;
        left:50%;
    }

    &::after { // This is dots
        z-index: 1;
        opacity: 0.25;
    }

    &::before {
        transition: box-shadow 0.15s ease-in-out;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
    }

    &:hover::before { // Hover shadow
        box-shadow: 0 0 0 15px #edf1f5;
    }

    &.checked{
        &:hover::before { // Hover shadow
            box-shadow: 0 0 0 20px #edf1f5;
        }

        &::after { // Dots
            animation-name: popdots;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
        }
    }

    &.checked.current .icon {
        color: currentColor;
    }

    &.checked .icon {
        transform: scale(1);
        color: $favorite-star-on-color;


        &::before { // Star icon
            animation-name: popstar;
            animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
        }

        &::after { // Circle
            height: 45px;
            width: 45px;
            opacity: 1;
            border: 1px solid transparent;
            transition: height 0.75s ease-in-out,
                        width 0.75s ease-in-out,
                        border 0.75s ease-in-out;
        }
    }
}

.input {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: 0;
}

.icon {
    font-size: $favorite-star-font-size;
    color: $favorite-star-off-color;
    position:relative;
    transform: scale(0.85);

    .sm & {
        font-size: $favorite-star-font-size-sm;
    }

    &::before { // Star Icon
        position: relative;
        z-index: 2;
    }

    &::after { // Circle
        content: '';
        opacity: 0;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 6px solid currentColor;
        border-radius: 1000px;
        height: 5px;
        width: 5px;
    }
}

/* Animation specific variables */
$third-size: math.div($favorite-star-dots-size, 3);
$quarter-size: math.div($favorite-star-dots-size, 4);

@keyframes popdots {
    0% {
        box-shadow:
            0 0 0 $favorite-star-dots-size currentColor,
            0 0 0 $favorite-star-dots-size currentColor,
            0 0 0 $favorite-star-dots-size currentColor,
            0 0 0 $favorite-star-dots-size currentColor,
            0 0 0 $favorite-star-dots-size currentColor;
    }
    85% {
        box-shadow:
            -20px -30px 0 $third-size currentColor,
            20px -30px 0 $third-size currentColor,
            0 40px 0 $third-size currentColor,
            30px  15px 0 $third-size currentColor,
            -30px  15px 0 $third-size currentColor;
    }
    100% {
        box-shadow:
            -20px -30px 0 $quarter-size transparent,
            20px -30px 0 $quarter-size transparent,
            0 40px 0 $quarter-size transparent,
            30px  15px 0 $quarter-size transparent,
            -30px  15px 0 $quarter-size transparent;
    }
}

@keyframes popstar {
    0% {
        transform: scale(0.5);
    }
    30% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(0.75);
    }
    60% {
        transform: scale(1.25);
    }
    65% {
        transform: scale(0.85);
    }
    70% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(0.9);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}