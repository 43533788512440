@use "sass:map";

.sid-btn-tour {
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 30px;
    box-shadow: 0 10px 10px rgba($primary, 0.2);
    height: 30px;
    width: 30px;
    overflow: hidden;
    color: $primary;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
    padding-right: 0;
    padding-left: 0;

    .mdi {
        text-align: center;
        display: block;
        width: 100%;
    }

    &:hover {
        background-color: $primary;
        color: $white;

        .mdi-arrow-right {
            animation-duration: 0.85s;
            animation-name: rollArrow;
            animation-timing-function: cubic-bezier(.7,-0.22,.14,1.2);
            animation-fill-mode: forwards;
        }
        .mdi-arrow-left {
            animation-duration: 0.85s;
            animation-name: rollArrow;
            animation-timing-function: cubic-bezier(.7,-0.22,.14,1.2);
            animation-direction: reverse;
            animation-fill-mode: forwards;
        }
    }
    &.btn-lg {
        border-radius: 50px;
        box-shadow: 0 20px 20px rgba($primary, 0.3);
        height: 50px;
        width: 50px;
    }
}

@keyframes rollArrow {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(100%);
    }
    51% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.sid-tour-thumbs-up {
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.7,-0.22,.14,1.2);
    animation-fill-mode: forwards;
    animation-name: popThumbsUp;
    transform: translateY(-50%) scale(0) rotate(-15deg);
    transform-origin:center;
    position: absolute;
    bottom: 0;
    z-index: 100;
    color: $primary;
    top: 50%;
    left: 0;
    right: 0;
    line-height: 1;
    margin: 0 auto;
    font-size: 7rem;
}

@keyframes popThumbsUp {
    0% {
        transform: translateY(-50%) scale(0) rotate(-15deg);
    }
    100% {
        transform: translateY(-50%) scale(1) rotate(0);
    }
}

.slide-up {
    display: block;
    transform: translateY(100%);
    opacity: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0.25s;
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}