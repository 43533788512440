.maintenance-container {
    min-height: 100vh;
    margin: 0;
    background-color: #f2f2f2;
    display: flex;
}
.maintenance-container-inner {
    align-self: center;
    text-align: center;
    flex-grow: 1;
}
.maintenance-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #727583;
}