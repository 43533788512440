.border {
    @each $key, $value in $grid-breakpoints {
        @each $side in $borderSides {
            &-#{$key}-#{$side} {
                @include media-breakpoint-up(#{$key}) {
                    border-#{$side}: $border-width solid $border-color !important;

                    &-0 {
                        border-#{$side}: none !important;
                    }
                }
            }
        }
    }
}

.border-2x {
    border-width: $border-width * 2 !important;
}

.border-dashed {
    border-style: dashed !important;
}

.rounded-right-0 {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-left-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 1 !important;
}