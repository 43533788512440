@use "sass:math";
@use "sass:map";
@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Container {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index:100000;
    width: 100%;

    &Inner {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow-y: auto;
        height: 100%;
    }

    &Notices {
        display: flex;
        flex-direction: column;
        min-width: 50vw;
        height: 100%;
        width: 500px;
        max-width: 100%;
        margin-left: v.$spacer;
        margin-right: v.$spacer;
        list-style-type: none;
        padding-left: 0;
    }
}

.Header {
    position: absolute;
    right: v.$spacer * 2;
    top: v.$spacer;
    transform: translateX(50%);
    z-index: 1;
    height: 100%;

    @media screen and (min-width: 992px) {
        top: map.get(v.$spacers, 4);
        right: calc(25vw - v.$spacer);
    }
}

.Btns {
    display: flex;
    flex-direction: column;
}