.overlay {
    pointer-events: none;
}

.msg {
    animation-name: popIn;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    left: 50%;
    max-width: 100%;
    min-width: 200px;
    pointer-events: all;
    top: 0;
    transform: translateX(-50%) scale(1);
    z-index: 10;
    width: 500px;

    @media screen and (min-width: 768px) {
        top: 100px;
    }
}

@keyframes popIn {
    0% {
        transform: translateX(-50%) scale(0.75);
    }
    100% {
        transform: translateX(-50%) scale(1);
    }
}

.blur {
    filter: blur(3px);

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        opacity: 0.5;
    }
}