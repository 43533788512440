@use "sass:color";
@use "sass:map";
@import './variables';
@import '~@spordle/ui-kit/assets/default/scss/style.scss';
@import '~@spordle/spordle-select/defaultStyles/spordleSelect.scss';

@import './components/badge';
@import './components/cardSectionTitle';
@import './components/dot';
@import './components/fileUpload';
@import './components/invoice';
@import './components/headerNotification';
@import './components/helpCenter';
@import './components/maintenance';
@import './components/overlayLoader';
@import './components/profileHeader';
@import './components/quickView';
@import './components/requestBar';
@import './components/sidePanel';
@import './components/supportRequestProfile';
@import './components/subSidebar';
@import './components/teamStaffCard';
@import './components/tinymce';
@import './components/toast';
@import './components/teamRoster';
@import './components/userDisplay';
@import './components/userImg';
@import './components/customAlert';
@import './components/manualRegistrationItems';
@import './components/watermark';
@import './newNav';
@import './components/envAlert';

@import './views/clinics/profile';
@import './views/clinics/colorPicker';
@import './views/dashboard/dashboard';
@import './views/dashboard/dashboardGrid';
@import './views/members/memberContacts';
@import './views/teams/roster';

@import './utilities/index'; // To add to UI-Kit

:root {
    color-scheme: light only;
}


// hardcoded here for now, it will eventually be globally fixed in ui-kit
.sidebar-nav ul .sidebar-item-header img {
    margin-right: 0;
    border-radius: 0;
}
.mini-sidebar .sidebar-item-header .userImg-container {
    margin-left: -5px;
}
// .org-name has been added
.sidebar-nav .org-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: auto;
}

.sidebar-nav .has-arrow::after {
    position: static;
    flex-shrink: 0;
    margin-left: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "/" !important;
}


// Use .rdt.right instead. this will soon be removed
.date-picker-left .rdtPicker {
    right: 0;
}

.date-picker-top .rdtPicker {
    top: unset;
    bottom: 100%;
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/visibility
// Visibility: hidden -> "The element cannot receive focus (such as when navigating through tab indexes)."
// Class btn is targeted instead of the tag because it takes longer to the browser to target tags
.modal:not(.show) .close,
.modal:not(.show) .modal-footer .btn {
    pointer-events: none;
    visibility: hidden;
}

// Simulates the same hover effect as in the table
.hoverable:hover {
    background-color: #d7eaff;
}

.dropdown-menu {
    box-shadow: $box-shadow;
    border-color: #E4E6EA;
}

.svg-current-color {
    fill: currentColor;
}

// FAT MARGIN BOTTOM WITH A PH
.mb-phat {
    margin-bottom: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);
}

.bg-primary-light {
    background-color: lighten($primary, 35%);
}

.changeOrgBtn {
    color: $text-muted;
    background-color: darken($light, 15%);
    margin-left: map-get($spacers, 2);
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.15s ease-in-out;
    height: 20px;
    width: 20px;

    &.lg {
        height: 30px;
        width: 30px;
    }

    &.sm {
        height: 14px;
        width: 14px;
    }

    &.xs {
        height: 15px;
        width: 15px;
    }

    &:hover {
        background-color: darken($light, 8%);
    }
}

.mantine-Tooltip-root > * {
    user-select: none;
}

.editable {
    // can't be helper class "bg-transparent" because the important of the helper overwrites the background hover state
    background-color: transparent;
}

.rotate-180 {
    transform: rotate(180deg);
}

.soft-shadow-primary {
    box-shadow: 0 10px 10px rgba($primary, 0.2);

    &-lg {
        box-shadow: 0 20px 20px rgba($primary, 0.3);
    }

}

.rounded-xl {
    border-radius: $border-radius-lg * 3;
}

.bg-light-hover {
    transition: background-color 0.15s ease-in-out;

    &:hover {
        background-color: $light;
    }
}

.reports-column-maxwidth {
    max-width: 300px;
}

.opacity-1 {
    opacity: 1 !important;
}

.ps__rail-y {
    z-index: 10;
}

.pretty-scrollbar {
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-track {
        background-color: $light;
    }

    &::-webkit-scrollbar-thumb {
        border: 3px solid $light;
        background-color: #aaa;
        border-radius: 1000px;

        @include transition(background-color 0.15s ease-in-out);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #999;
    }
}

.member-field i {
    font-style: normal;
}
.shadow-solid-success {
    box-shadow: 0 0 0 2px rgba($success, 0.22);
}

.with-icon::before {
    margin-right: map.get($spacers, 1);
}

.has-loading-overlay {
	position: relative;

	&::after {
        border-radius: inherit;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		border-radius: inherit;
		left: 0;
		right: 0;
		background-color: $white;
		opacity: 0.5;
		z-index: 100;
	}

	&::before {
		content: '';
		display: inline-block;
		width: $spinner-width;
		height: $spinner-height;
		vertical-align: $spinner-vertical-align;
		background-color: $primary;

		// stylelint-disable-next-line property-disallowed-list
		border-radius: 50%;
		opacity: 0;
		animation: .75s linear infinite spinner-grow;
		position: absolute;
		top: calc(50% - ($spinner-height * 0.5));
		left: calc(50% - ($spinner-height * 0.5));
		z-index: 100;
	}
}

@media screen and (min-width: 1920px) {
    .sid-page-wrapper .page-content .container {
        max-width: 70vw;
    }
    .sid-page-wrapper .sidePanel.isOpen {
        width: 20vw;
    }
}