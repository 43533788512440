.env-header-container {
    position: relative;
}

// Desktop
.env-border {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            z-index: 9000;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid $value;
            pointer-events: none;
        }
    }
}
.env-header-element {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            border-top: 2px solid $value;
            z-index: 9000;
        }
    }
}
.env-header-content {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            display: inline-block;
            width:280px;
            padding-left: 5px;
            padding-right: 5px;
            height:20px;
            font-size: 12px;
            color: $value;
            background-color: lighten($value, 53%);
            border-bottom: 2px solid $value;
            border-left: 2px solid $value;
            border-right: 2px solid $value;
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            transition: all .30s ease-in-out;
        }
    }
}
.env-header-hover{
    width:320px !important;
    height:44px !important;
}

// Mobile
.env-mobile-border {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            z-index: 9001;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: $topbar-height - 2;
            border: 2px solid $value;
            pointer-events: none;
        }
    }
}
.env-mobile-header-element {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            line-height: 0;
            top: -18px;
            z-index: 9000;
        }
    }
}
.env-mobile-header-content {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            position: relative;
            box-sizing: border-box;
            display: inline-block;
            padding: 8px 20px;
            font-size: 12px;
            color: $value;
            background-color: lighten($value, 53%);
            border-top: 2px solid $value;
            border-left: 2px solid $value;
            border-right: 2px solid $value;
            border-top-left-radius: $border-radius-lg;
            border-top-right-radius: $border-radius-lg;
        }
    }
}