@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Contact {
    &:hover {
        .EmptyCheck {
            background-color: lighten(v.$primary, 20%);
            border-color: v.$primary;
        }
    }
}

.EmptyCheck {
    height: 30px;
    width: 30px;
    border: 1px solid #263238;
}