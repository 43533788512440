.sid-nav-dashboard {
    font-size: 0.8rem;

    &-icon {
        display: inline-block;
        height: 1.25em;
        width: 1.25em;
        position: relative;

        &-top-squares,
        &-bottom-squares {
            &::before,
            &::after {
                position: absolute;
                border: 1px solid currentColor;
                border-radius: 0.1em;
                content: "";
                display: block;
                height: 0.55em;
                width: 0.55em;
            }
        }

        &-top-squares {
            &::before,
            &::after {
                top: 0;
            }
            &::before {
                right: 0;
            }
            &::after {
                left: 0;
            }
        }
        &-bottom-squares {
            &::before,
            &::after {
                bottom: 0;
            }
            &::before {
                right: 0;
            }
            &::after {
                left: 0;
            }
        }
    }
}