.dashboard-grid {
    &-add {
        border: 2px dashed darken($light, 15%);
    }
    &-small-content {
        transform: translateY(50%);
    }
    &-drag-handle {
        cursor: move;
    }
    &-overlay-mobile {
        background-color: rgba($light-inverse, 0.8);
        z-index: $zindex-popover;
        color: lighten($dark, 10%);
        border: 2px dashed darken($light, 5%);
        border-radius: $border-radius;
    }
    &-overlay-mobile-manage-container {
        position: absolute;
        top: 0;
        right: 0;
        padding: map-get($spacers, 2);
        z-index: $zindex-popover + 10;
    }
    &-carousel .carousel-inner {
        height: 100%;
    }
    &-carousel .carousel-item.active,
    &-carousel .carousel-item-prev,
    &-carousel .carousel-item-next {
        display: flex;
        flex-direction: column;
    }

    &-tool {
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
    }

    &-toolbar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        max-width: 50px;
        width: 100%;
        z-index: 1000;

        &-icon-container {
            opacity: 1;
            transition: opacity 0.15s ease-in-out;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
        }

        &-inner {
            box-shadow: 0.25rem 0 0 100vw rgba($black, 0);
            position: relative;
            transition-delay: 0;
            background-color: $light;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            display: flex;
            max-width: 15px;
            flex-direction: column;
            transition: max-width 0.15s ease-in-out, border-radius 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &-icon {
                padding: map-get($spacers, 2);
                text-align: center;
                display: block;
                width: 100%;
            }
        }

        &-overlay {
            border-radius: $card-border-radius;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-shadow: inset 0 0 10px rgb(0, 0, 0, 0.1);
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
            pointer-events: none;
        }
        &:hover ~ &-overlay {
            opacity: 1;
        }
        &:hover &-inner {
            max-width: 40px;
            background-color: $white;
            border-top-right-radius: $border-radius-lg * 3;
            border-bottom-right-radius: $border-radius-lg * 3;
            box-shadow: 0.25rem 0 0 100vw rgba($black, 0.08), 10px 0 10px rgba($black, 0.15);
        }
        &:hover .dashboard-grid-tool {
            opacity: 1;
            transition-delay: 0.15s;
        }
        &:hover &-icon-container {
            opacity: 0;
        }
    }
}

.react-grid {
    &-layout {
        position: relative;
        transition: height 200ms ease;
    }
    &-item {
        transition: all 200ms ease;
        transition-property: left, top;

        img {
            pointer-events: none;
            user-select: none;
        }
        &.cssTransforms {
            transition-property: transform;
        }
        &.resizing {
            z-index: 1;
            will-change: width, height;
        }
        &.react-draggable-dragging {
            transition: none;
            z-index: 3;
            will-change: transform;
        }
        &.dropping {
            visibility: hidden;
        }
        &.react-grid-placeholder {
            background: lighten($primary, 25%);
            opacity: 0.5;
            transition-duration: 100ms;
            z-index: 2;
            user-select: none;
            border: 2px dashed $primary;
            border-radius: $border-radius;
        }
        > .react-resizable-handle {
            position: absolute;
            width: 20px;
            height: 20px;

            &::after {
                content: "";
                position: absolute;
                right: 3px;
                bottom: 3px;
                width: 5px;
                height: 5px;
                border-right: 2px solid rgba(0, 0, 0, 0.4);
                border-bottom: 2px solid rgba(0, 0, 0, 0.4);
            }
            &.react-resizable-handle {
                &-sw {
                    bottom: 0;
                    left: 0;
                    cursor: sw-resize;
                    transform: rotate(90deg);
                }
                &-se {
                    bottom: 0;
                    right: 0;
                    cursor: se-resize;
                }
                &-nw {
                    top: 0;
                    left: 0;
                    cursor: nw-resize;
                    transform: rotate(180deg);
                }
                &-ne {
                    top: 0;
                    right: 0;
                    cursor: ne-resize;
                    transform: rotate(270deg);
                }
                &-w, &-e {
                    top: 50%;
                    margin-top: -10px;
                    cursor: ew-resize;
                }
                &-w {
                    left: 0;
                    transform: rotate(135deg);
                }
                &-e {
                    right: 0;
                    transform: rotate(315deg);
                }
                &-n, &-s {
                    left: 50%;
                    margin-left: -10px;
                    cursor: ns-resize;
                }
                &-n {
                    top: 0;
                    transform: rotate(225deg);
                }
                &-s {
                    bottom: 0;
                    transform: rotate(45deg);
                }
            }
        }
    }
}

.react-resizable-hide > .react-resizable-handle {
    display: none;
}
