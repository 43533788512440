@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Img{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-bottom: v.$border-width solid v.$border-color;

    @media screen and (min-width: 768px) {
        border: v.$border-width solid v.$border-color;
    }

    &Container {
        width: 100%;
        position: relative;

        &::after {
            content: "";
            display: block;
            padding-bottom: 66%;
        }

        @media screen and (min-width: 768px) {
            width: 100px;
        }
    }
}

.Options {
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (min-width: 768px) {
        position: static;
    }
}