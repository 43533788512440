
@use "sass:map";

.sid-mobile {
    &-page-wrapper {
        padding-top: $topbar-height !important; // REFACTOR TOREMOVE
    }
    &-nav-link {
        &:focus,
        &:active,
        &:focus:active,
        &:hover,
        &.is-active {
            color: $dark;
        }
    }
    &-header {
        position: fixed !important; // REFACTOR TOREMOVE
        top: 0;
        z-index: $zindex-fixed !important; // REFACTOR TOREMOVE

        &-period {
            max-width: 50vw;
            flex-shrink: 0;
            margin-left: auto;
            transition: max-width 0.15s ease-in-out, opacity 0.15s ease-in-out;
            white-space: nowrap;


            .btn-outline-secondary {
                box-shadow: inset 0 2px 5px rgba($dark, 0.1);
                border: 1px solid $border-color;
                background-color: $light;

                &:hover {
                    color: $dark;
                }
            }

            .show-sidebar & {
                max-width: 0;
                opacity: 0;
            }
        }
        &-org {
            align-items: center;
            border-radius: $border-radius;
            background-color: transparent;
            border: none;
            outline: none;
            text-align: left;
            cursor: pointer;
            display: flex;
            margin-left: map.get($spacers, 2);
            margin-right: map.get($spacers, 2);
            min-width: 0;
            overflow: hidden;
            padding: map.get($spacers, 1);
            transition: max-width 0.15s ease-in-out;
            width: 100%;

            &:disabled {
                pointer-events: none;
            }

            &-name {
                color: $dark;
                font-weight: 800;
                margin-right: auto;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }

            &-change {
                margin-left: map.get($spacers, 1);
            }
        }
        &-burger{
            &-icon {
                box-sizing: content-box;
                background-color: $body-color;
                background-clip: content-box;
                border-radius: 2px;
                position: relative;
                padding-top: 0.3rem;
                padding-bottom: 0.3rem;
                height: 2px;
                width: 2rem;
                transition: background-color 0.15s ease-in-out;

                .sid-mobile-burger-is-open & {
                    background-color: transparent;

                    &::before {
                        transform: translateY(50%) rotate(45deg);
                        bottom: 50%;
                    }

                    &::after {
                        transform: translateY(-50%) rotate(-45deg);
                        top: 50%;
                    }
                }

                &::before,
                &::after {
                    background-color: $body-color;
                    border-radius: 2px;
                    content: "";
                    display: block;
                    height: 2px;
                    left: 0;
                    right: 0;
                    position: absolute;
                    transition: transform 0.15s ease-in-out, bottom 0.15s ease-in-out, top 0.15s ease-in-out;

                }
                &::before {
                    bottom: 0;
                }
                &::after {
                    top: 0;
                }
            }
            &-btn {
                align-items: center;
                cursor: pointer;
                border: 2px solid $border-color;
                border-radius: $border-radius-lg;
                box-shadow: $box-shadow-sm;
                display: flex;
                height: 40px;
                line-height: 1;
                justify-content:center;
                padding: map.get($spacers, 2);
                width: 40px;
            }
        }
    }
    &-burger{
        &-icon {
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 20px;
                height: 1px;
                background-color: $dark;
            }
            &::after {
                content: "";
                display: block;
                width: 20px;
                height: 1px;
                background-color: $dark;
            }
        }
        &-btn {
            align-items: center;
            border: 2px solid $border-color;
            border-radius: $border-radius;
            box-shadow: $box-shadow-sm;
            display: flex;
            height: 40px;
            line-height: 1;
            justify-content:center;
            padding: map.get($spacers, 2);
            width: 40px;
        }
    }
    &-menu {
        background-color: transparent;
        bottom: 0;
        border-right: $border-width solid $border-color;
        left: -100vw;
        padding-top: $topbar-height;
        padding-bottom: $topbar-height;
        position: fixed;
        top: 0;
        z-index: 1029;
        width: 200vw;
        transition: left 0.15s ease-in-out, background-color 0.15s ease-in-out 0.15s;
        pointer-events: none;

        &-square-item {
            color: $secondary;
            border-radius: $border-radius;
            background-color: $light-inverse;

            &.active {
                color: $fedColor;
                background-color: $light;
            }
        }

        &-inner {
            box-shadow: $box-shadow;
            background-color: $white;
            max-width: 450px;
            min-width: 300px;
            width: 80vw;
        }

        &.sid-mobile-burger-is-open {
            left: 0;
            background-color: rgba(0,0,0,0.2);
            pointer-events: all;
        }

        .sidebar-nav ul .sidebar-item {
            width: 100%; // TODO REFACTOR - remove this selector
        }

        .sidebar-nav ul .sidebar-item .sidebar-link {
            color: $dark; // TODO REFACTOR - remove this selector
            opacity: 1;
        }
        .nav-small-cap.sid-sidebar-item-title {
            font-size: 0.85rem;
        }
        .sidebar-item.sidebar-item-header {
            font-size: 0.9rem;
        }

        &-item {
            align-items: center;
            display: flex;
            font-weight: bold;
            padding: $spacer;
            width: 100%;
        }
    }
    &-footer {
        box-shadow: $topbar-shadow;
        margin-bottom: 0;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $zindex-fixed;

        &-inner {
            background-color: $white;
            box-shadow: $box-shadow-sm;
            border-top: 1px solid $border-color;
            height: $topbar-height;
            padding: $spacer $spacer map.get($spacers, 1) $spacer;
        }

        &-list {
            align-items: center;
            display: flex;
            list-style-type: none;
            justify-content: space-between;
            margin-bottom: 0;
            padding: 0;
        }

        &-item {
            align-items: center;
            display: flex;
            flex: 1 1 0;
            justify-content: center;

            &-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 40px;
                min-width: 40px;
                border-radius: $border-radius-lg;
                position: relative;

                $selector: #{&};

                @at-root {
                    .sid-mobile-nav-link.is-active,
                    #{$selector}.is-active {
                        color: $dark;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            background-color: $fedColor;
                            top: 0;
                            left: 0;
                            right: 0;
                            height: 3px;
                            width: 100%;
                            transform: translateY(-$spacer);
                            margin: ($topbar-height - 3px - 1px) auto 0 auto;
                        }
                    }
                }

                &.is-open {
                    color: $dark;
                    background-color: $light;
                }

                &.is-small.is-open::before {
                    content: "";
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: -100;
                }
            }
        }

        &-notif {
            background-color: $danger;
            color: $white;
            font-size: 8px;
            position: absolute;
            margin-top: -(map.get($spacers, 2));
            margin-right: -(map.get($spacers, 2));
            top: 0;
            right: 0;
        }
        &-menu {
            background-color: $light;
            bottom: 0;
            list-style-type: none;
            height:100%;
            opacity: 0;
            top: 0;
            padding: 0 0 $topbar-height 0;
            position: fixed;
            pointer-events: none;
            transition: transform 0.2s ease-in, opacity 0.15s linear;
            left: 0;
            right: 0;
            transform: translateY(100%);
            z-index: -1;

            &-inner {
                list-style-type: none;
                height: 100%;
                padding: $spacer;
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;

            }

            &-photo {

                &.userImg-container {
                    border: 4px solid $white;
                    box-shadow: none;
                }

                &-container {
                    position: absolute;
                    top: 0;
                    transform-origin: left center;
                    transition: transform 0.15s ease-in-out;
                    transform: translateY(-50%);
                    will-change: transform;

                    &.is-smaller {
                       transform: translateY(0) scale(0.5);
                    }
                }
            }

            &-button {
                color: $body-color;
                font-weight: bold;
                padding: map.get($spacers, 4);
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: $border-radius-lg;
                margin-top: auto;
                margin-bottom: $spacer;
                width: 100%;
            }

            &-subitem {
                align-items: center;
                background-color: $white;
                display: flex;
                border-radius: $border-radius;
                margin-left: -$spacer;
                margin-right: -$spacer;
                font-weight: 500;
                margin-bottom: $spacer;
                padding: map.get($spacers, 4) $spacer;
                position: relative;

                &-link {
                    align-items: center;
                    color: $dark;
                    display: flex;
                    width: 100%;
                }

                &.active &-link,
                &-link:hover,
                &-link:focus {
                    color: $dark;
                }

            }

            &.is-small &-subitem {
                padding: map.get($spacers, 2) $spacer;
            }

            &.is-open {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }

            &.is-small {
                box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
                border-top-left-radius: $border-radius-lg * 2;
                border-top-right-radius: $border-radius-lg * 2;
                background-color: $white;
                height: auto;
                min-height: 300px;
                max-height: calc(100vh - $topbar-height * 2);
                padding: $spacer $spacer * 2 $topbar-height $spacer * 2;
                top: unset;
            }
        }
    }
}