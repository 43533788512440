.completableCheck {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        background-color: currentColor;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        height: 50%;
        width: 50%;
        transition: transform 0.15s ease-in-out;
        border-radius: 1000px;
        opacity: 0.25;
    }

    &:hover::after {
        transform: translate(-50%, -50%) scale(1);
    }
}