.target {
    cursor: help;
    margin-left: -3px;
    margin-right: -3px;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 3px;
    transition: transform 0.15s ease-in-out;

    &:hover {
        transform: scale(1.02);
    }
}