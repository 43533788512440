.clinicColor {
    padding: map-get($spacers, 1);
    display: inline-block;
    border: $border-width solid $border-color;
    background-color: #fff;
    border-radius: $border-radius;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
        background-color: $gray-100;
    }

    &-currentColor {
        @include reset-btn();
        height: map-get($spacers, 3);
        width: map-get($spacers, 3);
        display: block;
        background-color: #3994FF;
        border-radius: $border-radius;
    }

    // dropdown Start
    &-wrapper {
        position: relative;
    }

    &-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: $zindex-dropdown;
        margin-top: map-get($spacers, 1);

        display: inline-flex;
        padding: map-get($spacers, 1);
        border: $border-width solid $border-color;
        box-shadow: $box-shadow-sm;
        background-color: #fff;

        &-color {
            @include reset-btn();
            height: map-get($spacers, 3);
            width: map-get($spacers, 3);
            display: block;
            margin: map-get($spacers, 1);
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            cursor: pointer;

            &:hover {
                @each $colorKey, $colorValue in $clinicColors {
                    &.bg-clinic-#{$colorKey} {
                        box-shadow: 0px 0px 0px map-get($spacers, 1) rgba($colorValue, 0.35);
                    }
                }
            }
        }
    }
}