@use "sass:math";

$envelopWidth: 5em;
$envelopHeight: math.div($envelopWidth * 65, 100);
$halfEnvelopHeight: math.div($envelopHeight, 2);
$animDelay: 600ms;
$animLetterDur: 600ms;
$animCloseDur: 500ms;

.Container {
    display: inline-block;
    line-height: 1;
    padding-top: $halfEnvelopHeight;
    overflow: hidden;
    animation-name: placeContainer;
    animation-duration: $animCloseDur;
    animation-timing-function: ease-in-out;
    animation-delay: $animDelay + $animLetterDur;
    animation-fill-mode: forwards;
}

@keyframes placeContainer {
    from {
        padding-top: $halfEnvelopHeight;
        padding-bottom: 0;
    }
    to {
        padding-top: math.div($halfEnvelopHeight, 2);
        padding-bottom: math.div($halfEnvelopHeight, 2);
    }
}

.Envelop {
    border-radius: 0.2em;
    display: inline-block;
    line-height: 1;
    background-color: currentColor;
    position: relative;
    width: $envelopWidth;
    height: 0;
    padding-bottom: $envelopHeight;
}

.HorizontalFlaps {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.2);

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: $halfEnvelopHeight solid transparent;
        border-right: math.div($envelopWidth, 2) solid currentColor;
        border-bottom: $halfEnvelopHeight solid transparent;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 10;
        border-radius: 0.2em;
    }

    &::before {
        content: "";
        border-radius: 0.2em;
        width: 0;
        height: 0;
        border-top: $halfEnvelopHeight solid transparent;
        border-left: math.div($envelopWidth, 2) solid currentColor;
        border-bottom: $halfEnvelopHeight solid transparent;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 20;
    }
}

.Letter {
    display: block;
    right: 0;
    left: 0;
    background: #ffffff;
    border-top-right-radius: 0.2em;
    border-top-left-radius: 0.2em;
    position: absolute;
    height: 100%;
    bottom: 50%;
    width: 90%;
    margin: 0 auto;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
    animation-name: slideIn;
    animation-delay: $animDelay;
    animation-duration: $animLetterDur;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    transform-origin: bottom center;
    z-index: 5;

    &::after {
        content: "";
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        height: 0.2em;
        top: 0.3em;
        left: 0.3em;
        right: 0;
        width: 80%;
        display: block;
        box-shadow: 0 0.3em 0 rgba(0,0,0,0.3);
    }
}

@keyframes slideIn {
    from {
        height: 100%;
    }
    to {
        height: 48%;
    }
}

.TopFlap {
    width: 0;
    height: 0;
    border-radius: 0.2em;
    border-left: math.div($envelopWidth, 2) solid transparent;
    border-right: math.div($envelopWidth, 2) solid transparent;
    border-bottom: $halfEnvelopHeight solid currentColor;
    bottom: 100%;
    left: 0;
    right: 0;
    position: absolute;
    animation-name: closeFlap;
    animation-duration: $animCloseDur;
    animation-timing-function: ease-in-out;
    animation-delay: $animDelay + $animLetterDur;
    animation-fill-mode: forwards;
    transform-origin: bottom center;
    z-index: 0;
}

@keyframes closeFlap {
    from {
        transform: scaleY(1);
        z-index: 6;
    }
    to {
        transform: scaleY(-1);
        z-index: 6;
    }
}

.BottomFlap {
    width: 0;
    height: 0;
    border-left: $envelopWidth solid transparent;
    border-right: 0 solid transparent;
    border-bottom: $envelopHeight solid rgba(250,250,250,0.2);
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 20;
}