.roster-staff-qualifications {
    // max-height: 5em * $line-height-base; // 5 qualifications
    overflow: hidden;
    position: relative;
    transition: all .5s ease;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        height: 1em;
        background: linear-gradient(to top, #fff 0%, rgba(255,255,255,0) 100%);
    }

    &.isExpanded {
        &::after {
            content: none;
        }
    }
}