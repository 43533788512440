.bar {
    --progress-percent: 0;

    width: 100%;
    height: 0.8em;
    border-radius: 1000px;
    position: relative;
    overflow: hidden;

    &::after {
        background-color : currentColor;
        bottom: 0;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--progress-percent);
        transition: width 0.15s ease-in-out;
    }
}