// critical
.bg-priority-1 {
    background-color: $danger !important;
}
// urgent
.bg-priority-2 {
    background-color: $orange !important;
}
// normal
.bg-priority-3 {
    background-color: $orange !important;
}
// low
.bg-priority-4 {
    background-color: $warning !important;
}
// lowest
.bg-priority-5 {
    background-color: $yellow !important;
}