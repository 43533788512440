.carousel {
    &-dots {
        &-container {
            display: flex;
            justify-content: center;
            list-style-type: none;
            margin-bottom: 0;
            margin-top: $spacer;
            padding: 0;
        }
        &-single {
            background-color: lighten($dark, 60%);
            border-radius: 7px;
            cursor: pointer;
            height: 7px;
            margin: 0 map-get($spacers, 1);
            width: 7px;

            &.active, &:hover {
                background-color: $dark;
            }
        }
    }
    &-inner {
        &-card-item {
            min-height: $font-size-base * 1.5 * 3; // Font size * line-height * number of lines
        }
    }
}