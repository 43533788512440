.userImg {
    object-fit: contain;

    &-container {
        box-shadow: $box-shadow;
        background-color: $white;
        border-radius: 2000px;
        border: $border-width solid $border-color;
        display: inline-block;
        overflow: hidden;
        padding: map-get($spacers, 2); // assuming this is enough, it might still sometimes crop corners
        vertical-align: middle; // fixed align baseline img vs text - HCR-3242
        flex-shrink: 0; // just in case this is a flex-item, it should never be squashed
    }

    &-abbr {
        background-color: $light;
        color: $dark;
        padding: map-get($spacers, 2);
        border-radius: 2000px;
        border: $border-width solid $border-color;
        font-weight: $font-weight-bold;
        box-shadow: $box-shadow;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 100%;
        vertical-align: middle; // fixed align baseline img vs text - HCR-3242
        flex-shrink: 0; // just in case this is a flex-item, it should never be squashed
    }
}