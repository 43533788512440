@use "sass:map";

.sid-breadcrumbs {
    font-size: 0.78rem;

    .breadcrumbs {
        align-items: center;
    }

    &-item {
        align-items: center;
        display: flex;
        min-width: 0;
    }

    &-link {
        color: $secondary;
        position: relative;
        font-weight: 500;
        width: 100%;

        &:hover {
            text-decoration: underline;

            &::before {
                opacity: 1;
            }
        }
    }
}