.teamRoster-status {
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75em;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    font-weight: $font-weight-medium;
    border-radius: 50%;
    background-color: lighten($primary, 33%);
    border: 1px solid $primary;
    color: $primary;

    &-danger {
        background-color: lighten($danger, 50%);
        border: 1px solid $danger;
        color: $danger;
    }
}