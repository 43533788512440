@use "sass:map";

// quick helper fix, should eventually be in ui-kit
.text-excel {
    color: #21A464 !important;
}

.text-dev {
    color: hotpink !important;
}

.text-word {
    color: #508EF5 !important;
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-inherit {
    color: inherit !important;
}

.line-height-1 {
    line-height: 1;
}

.line-height-0 {
    line-height: 0 !important;
}

.label-permission {
    color: $text-muted;
    .power-user & {
        color: map.get($theme-colors, "purple") !important;
    }
}