@use "sass:map";

.sid-nav {
    &-bar {
        white-space: nowrap;
    }
    &-item,
    &-bar .navbar-nav > .nav-item {
        align-items: center;
        color: $dark;
        display: flex;
        justify-content: center;
        margin-right: $spacer;

        &-logo {
            flex-shrink: 0;
            padding-right: $spacer;
            margin-right: $spacer;
            margin-left: $spacer;
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                height: 50%;
                bottom: 0;
                transform: translateY(-50%);
                border-right: 1px solid $light;
            }
        }

        .dropdown-menu {
            border: 1px solid #E4E6EA; // REFACTOR: To remove
        }

        .dropdown-header {
            text-transform: uppercase;
            font-weight: 800;
            color: $dark-title-color;
            padding-top: $spacer;
            padding-right: map.get($spacers, 4);
            padding-left: map.get($spacers, 4);
        }

        .dropdown-item {
            font-size: $nav-font-size;
            transition: background-color 0.15s ease-in-out;
            position: relative;
            padding-right: map.get($spacers, 4);
            padding-left: map.get($spacers, 4);

            &:hover,
            &:focus {
                background-color: $light-inverse;
                color: $dark;

                &::before {
                    opacity: 0;
                }

            }

            &::before {
                content: "";
                display: inline-block;
                background-color: $fedColor;
                position: absolute;
                height: 60%;
                opacity: 0;
                top: 50%;
                transform: translateY(-50%);
                bottom: 0;
                left: 0;
                width: 3px;
                transition: opacity 0.15s ease-in-out;
            }

        }

        .dropdown-item.selected {
            background-color: $light-inverse;
            color: $dark;

            &::before {
                opacity: 1;
            }
        }

    }

    &-link,
    &-bar .navbar-nav > .nav-item > .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        color: $dark; // REFACTOR TOREMOVE
        line-height: 1.4rem !important; // REFACTOR TOREMOVE: remove !important
        font-size: $nav-font-size !important; // REFACTOR TOREMOVE: remove !important
        font-weight: 500;
        padding-right: $navbar-nav-link-padding-x !important; // REFACTOR TOREMOVE: remove !important
        padding-left: $navbar-nav-link-padding-x !important; // REFACTOR TOREMOVE: remove !important
        padding-top: $navbar-nav-link-padding-x !important; // REFACTOR TOREMOVE: remove !important
        padding-bottom: $navbar-nav-link-padding-x !important; // REFACTOR TOREMOVE: remove !important
        transition: 0.15s background-color ease-in-out, 0.15s box-shadow ease-in-out;
        height: auto !important; // REFACTOR TOREMOVE: remove line
        min-height: (($navbar-nav-link-padding-x * 2) + 1.4rem);
    }

    &-bar &-item:hover &-link,
    &-bar &-item:focus &-link,
    &-bar &-item.show &-link,
    &-bar &-link.is-active,
    &-bar .navbar-nav > .nav-item.show > .nav-link,
    &-bar .navbar-nav > .nav-item:focus > .nav-link,
    &-bar .navbar-nav > .nav-item:hover > .nav-link {
        background-color: $light-inverse;

        &.is-dashboard {
            color: $fedColor;
        }
    }

    &-item.show &-link,
    &-bar .navbar-nav > .nav-item.show > .nav-link,
    &-bar .navbar-nav > .nav-item > .nav-link:active {
        background-color: $light-inverse;
        box-shadow: inset 0 2px 5px rgba($dark, 0.15);
    }

    &-chevron {
        color: $fedColor;
        font-size: 0.5em;
    }
}