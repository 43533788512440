@use '~@spordle/ui-kit/assets/default/scss/all/custom/variables.scss' as v;
@import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/mixins.scss';

.submitButtonsCol {
    order: 100;
    margin-left: auto;

    @include media-breakpoint-up(lg, v.$grid-breakpoints) {
        order: unset;
    }
}