@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

:global(.btn).compactBtn {
    background-clip: padding-box;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 0;
    border-top: 1px solid v.$input-border-color;
    border-bottom: 1px solid v.$input-border-color;
    border-left: 1px solid v.$input-border-color;
}

.compactSelect :global(.form-control) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}