@use '~@spordle/ui-kit/assets/default/scss/all/custom/variables.scss' as v;
@use "sass:map";

.Container,
:global(.userDisplay).Container {
    border-style: dashed;
    border-color: v.$primary;
    margin-bottom: v.$spacer;
    width: 100%;

    &:focus {
        outline: none;
    }
}

.SvgContainer {
    align-items: center;
    background-color: map.get(v.$theme-colors, "light");
    border: v.$border-width solid v.$border-color;
    border-radius: 40px;
    box-shadow: v.$box-shadow;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.Title,
.Subtitle {
    display: block;
}

.Svg {
    color: v.$primary;
}

:global(.power-user) {
    > .Container {
        border-color: map.get(v.$theme-colors, "purple");

        .Svg,
        .Title,
        .Subtitle {
            color: map.get(v.$theme-colors, "purple");
        }
    }
}