@use "~@spordle/ui-kit/assets/default/scss/all/custom/variables" as v;

.Label {
    display: block;
    position: relative;
    height: 22px;
    margin-bottom: 0;
    width: 22px;

    &::before {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        color: v.$white;
        opacity: 0;
        z-index: 1;
    }

    &::after {
        content: "";
        border: 1px solid v.$border-color;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.05);
        background-color: lighten(v.$gray-100, 5%);
        border-radius: 25px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &.Checked{
        &::after {
            border-color: v.$primary;
            background-color: v.$primary;
            box-shadow: none;
        }
        &::before {
            opacity: 1;
        }
    }

    &.Indeterminate{
        &::after {
            border-color: lighten(v.$primary, 20%);
            background-color: lighten(v.$primary, 20%);
        }
        &::before {
            opacity: 1;
        }
    }
}

.Input {
    position: absolute;
    opacity: 0;
}

.Container {
    transition: opacity 0.15s ease-in-out, width 0.15s ease-in-out, margin 0.25s ease-in-out, padding 0.25s ease-in-out;
    opacity: 0;
    width: 0;
    margin-right: 0;

    &.Show {
        margin-right: v.$spacer;
        opacity: 1;
        width: 22px;
    }
}